<template>
  <div class="about bg-dark">
    <h1>Test / Essai</h1>
    <h3>csrf {{ $store.state.store_csrf.token }}</h3>
    <h1>Réponse API</h1>
    <pre style="color:white;text-align:left;">{{ Api }}</pre>
  </div>
</template>

<script>
import provider from '../services/provider'

export default {
  data() {
    return {
      Api: 0,
    }
  },
  computed: {
    // ...mapState(['store_csrf']),
    csrf() {
      return document
        .querySelector('meta[name="X-XSRF-TOKEN"]')
        .getAttribute('content')
    },
  },
  async mounted() {
    this.Api = await provider.get('provider/token/get')
    // this.getCsrf()

    // this.$store.dispatch('store_csrf/get')
    const test = await this.$store.dispatch('store_csrf/test')
    console.warn('TEST: ' + test)
  },
  beforeDestroy() {
    this.$store.dispatch('store_csrf/delete')
  },
  methods: {
    getCsrf() {
      this.$store.dispatch('store_csrf/get')
    },
    checkForm: function(e) {
      e.preventDefault()
      this.errors = []

      if (this.name.length === 0) {
        return this.errors.push('Name required.')
      }
      this.test = document
        .querySelector('meta[name="X-XSRF-TOKEN"]')
        .getAttribute('content')
    },
  },
}
</script>
